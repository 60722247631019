<script setup lang="ts">
defineProps<{ content: string; title: string; confirmText: string; cancelText?: string | null }>()

const modal = ref<HTMLElement | null>(null)
const emits = defineEmits(['cancel', 'ok'])

onClickOutside(modal, () => {
	emits('cancel')
})
</script>

<template>
	<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
		<div class="fixed inset-0 bg-gray-500/75 transition-opacity" />

		<div class="fixed inset-0 z-10 overflow-y-auto">
			<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
				<div ref="modal" class="relative overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
					<div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
						<div class="sm:flex sm:items-start">
							<div class="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
								<i-gg-danger class="h-6 w-6 text-red-600" />
							</div>
							<div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
								<h3 id="modal-title" class="text-base font-semibold leading-6 text-gray-900">
									{{ title }}
								</h3>
								<div class="mt-2">
									<p class="text-sm text-gray-500" v-html="content" />
								</div>
							</div>
						</div>
					</div>
					<div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
						<button-base type="button" variant="primary" class="mb-3 w-full sm:mb-0 sm:ml-3 sm:w-auto" @click.self="emits('ok')">
							{{ confirmText }}
						</button-base>
						<button-base type="button" variant="secondary" class="w-full sm:w-auto" @click.self="emits('cancel')">
							{{ cancelText ?? t('app.cancel') }}
						</button-base>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
